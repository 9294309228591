import AnimationVertical from 'components/less/AnimationVertical';
import React, { Component } from 'react';
import { Nav, Tab } from 'react-bootstrap';

class SectionTabs2 extends Component {

    getHeader = () => {
        let datos = [];
        this.props.dataSource.detailCategoy.map((header, i) => {
            datos.push(
                <Nav.Item>
                    <Nav.Link eventKey={header.eventKey}>
                        <span>{header.category}</span>
                    </Nav.Link>
                </Nav.Item>

            );
        });
        return datos;
    }

    getContent = () => {
        let datos = [];
        this.props.dataSource.detailCategoy.map((item, i) => {
            datos.push(
                <Tab.Pane key={'tab-' + i} eventKey={item.eventKey} className="fadeInUp animated">
                    <div class="clearfix">
                        <div key={'stb2-' + i + "2"} class="text">
                            <p>{item.body.description}</p>
                        </div>
                    </div>
                </Tab.Pane>
            );
        });
        return datos;
    }
    render() {
        const { ...props } = this.props;
        const { dataSource, marginTop } = props;
        const classSection = marginTop ? "home-page services-section-five services-section-top" : "home-page services-section-five";
        return (
            <>
                {/* <!-- Why Choose Us Section --> */}
                <section class="home-page why-chooseus-section">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">{dataSource.subtitle}</div>
                                        <h2>{dataSource.title}</h2>
                                        <p>{dataSource.description}</p>
                                    </div>
                                    <Tab.Container defaultActiveKey="first">
                                        {this.props.dataSource.detailCategoy.length > 1 && (
                                            <Nav variant="pills" className="nav nav-tabs tab-btn-style-one">
                                                {this.getHeader()}
                                            </Nav>
                                        )}
                                        <Tab.Content className="tabs-content">
                                            {this.getContent()}
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one"><img src={dataSource.image1} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
export default SectionTabs2;