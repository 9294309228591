import React, { Component } from 'react';

class SectionListOptions extends Component {

    getHeader = () => {
        let datos = [];
        this.props.dataSource.detailCategoy.map((header, i) => {
            var classItem = this.props.dataSource.md ? this.props.dataSource.md : " col-md-6 col-lg-6";
            datos.push(
                <>
                    <div class={classItem}>
                        <div class="icon-box">
                            <div class="icon"><img src={header.img} alt="" /></div>
                            <h2>{header.title}</h2>
                            <div class="text">{header.category}</div>
                        </div>
                    </div>
                </>
            );
        });
        return datos;
    }
    render() {
        const { ...props } = this.props;
        return (
            <>
                <section class=" services-section-four style-two" >
                    <div class="auto-container">
                        {this.props.dataSource.title && (
                            <div class={this.props.dataSource.center ?
                                "sec-title light text-center"
                                : "sec-title light"}>
                                <div class="sub-title">{this.props.dataSource.subtitle}</div>

                                <h2 style={{ color: '#333' }}>{this.props.dataSource.title}</h2>
                            </div>
                        )}
                        <div class="row">
                            {this.getHeader()}
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
export default SectionListOptions;