import { Row } from 'antd';
import AnimationVertical from 'components/less/AnimationVertical';
import React, { Component } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class SectionServices extends Component {

    getHeader = () => {
        let datos = [];
        this.props.dataSource.detailCategoy.map((header, i) => {
            datos.push(
                <Nav.Item>
                    <Nav.Link eventKey={header.eventKey}>
                        <div class="icon-box">
                            <div class="icon">
                                <span class={header.icon}>
                                </span>
                            </div>
                            <h5>{header.category}</h5>
                        </div>
                    </Nav.Link>
                </Nav.Item>
            );
        });
        return datos;
    }

    getContent = () => {
        let datos = [];
        this.props.dataSource.detailCategoy.map((item, i) => {
            datos.push(
                <Tab.Pane key={'tab-' + i} eventKey={item.eventKey} className=" fadeInUp animated">
                    <div class="row align-items-center">
                        <Row>
                            <div key={'ssp-' + i + "1"} class="col-lg-6">
                                <div class="image"><img src={item.body.image} alt="" /></div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content pl-lg-4"> 
                                        <div key={'ssp-' + i + "2"} class="sec-title">
                                            <div class="sub-title">{item.body.subtitle} </div>
                                            <h2>{item.body.title} </h2>
                                        </div>
                                        <div key={'ssp-' + i + "3"} class="text">{item.body.description} </div>
                                        {item.body.href && (
                                            <div key={'ssp-' + i + "4"} class="link-btn">
                                                <Link to={item.body.href} class="theme-btn btn-style-one">
                                                    <span class="btn-title">{item.body.bnt}</span>
                                                </Link>
                                            </div>)} 
                                </div>
                            </div>
                        </Row>
                    </div>
                </Tab.Pane>
            );
        });
        return datos;
    }
    render() {
        const { ...props } = this.props;
        const { dataSource, marginTop } = props;
        const classSection = marginTop ? "home-page services-section-five services-section-top" : "home-page services-section-five ";
        return (
            <>
                <section class={classSection} >
                    <div class="auto-container">
                        <Tab.Container defaultActiveKey="first">
                            <Nav className="nav-tabs tab-btn-style-one">
                                {this.getHeader()}
                            </Nav>
                            <Tab.Content>
                                {this.getContent()}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                    <br />
                    <br />
                </section>
            </>
        )
    }
}
export default SectionServices;