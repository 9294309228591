import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import React from 'react';

class AnimationVertical extends React.PureComponent {
  render() {
    const { children } = this.props;
    return (
      <OverPack playScale={0.3} className="contentAnimation-wrapper">
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]} 
        >
          {children}
        </QueueAnim>
      </OverPack>
    );
  }
}

export default AnimationVertical;
