import React, { Component } from 'react'
import ModalImage from "react-modal-image";
import { Link } from 'react-router-dom';

class List extends Component {
    componentDidMount() {
        this.props.observer.observe(this.image)
    }

    render() {
        return (
            <div class="col-lg-4 col-md-6 gallery-block-one" key={this.props.image.id}>
                <div ref={node => { this.image = node }} >
                    <div class="inner-box">
                        <ModalImage
                            small={this.props.image.imgUrl}
                            large={this.props.image.imgUrl}
                        />
                        <div class="caption-title">
                            <h5>{this.props.image.name}</h5>
                            <h3> </h3>
                            <div class="view-project">
                                <Link data-fancybox="example gallery"
                                    class="zoom-btn"><span>+</span></Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default List