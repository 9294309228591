import { scrollToTop } from 'components/util';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 *
 * @param {*} props
 * @returns
 */
const LinkComponent = props => {
    const { to, btn, children } = props;
    return (
        <>
            {btn && (
                <Link to={to} class="theme-btn btn-style-one">
                    <div onClick={() => scrollToTop()}>
                        <span class="btn-title">{children}</span>
                    </div>
                </Link>
            )}
            {!btn && (
                <Link to={to}>
                    <div onClick={() => scrollToTop()}>
                        {children}
                    </div>
                </Link>
            )}
        </>
    );
};

export default LinkComponent;
