import LinkComponent from 'components/link';
import React, { Component } from 'react';


class SectionNews extends Component {

    getHeader = () => {
        let datos = [];
        this.props.dataSource.headerChildren.map((item, i) => {
            datos.push(
                <div class="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                    <div class="inner-box">
                        <div key={'hn-' + i + "-1"} class="image">
                            <LinkComponent to={item.href}><img src={item.image} alt="" />
                            </LinkComponent>
                        </div>
                        <div key={'hn-' + i + "-2"} class="lower-content">
                            <div class="category">{item.category}</div>
                            <div class="text">{item.subtitle}</div>
                        </div>
                    </div>
                </div>
            );
        });
        return datos;
    }

    render() {
        const { ...props } = this.props;
        const { dataSource } = props;
        return (
            <>
                <section class="news-section">
                    <div class="sec-bg"
                        style={{ backgroundImage: "url(" + dataSource.background + ")" }}>
                    </div>
                    <div class="auto-container">
                        <div class="sec-title text-center light">
                            <div class="sub-title">{dataSource.title}</div>
                            <h2>{dataSource.subtitle}</h2>
                        </div>
                        <div class="row">
                            {this.getHeader()}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
export default SectionNews;