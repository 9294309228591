import { ContactoDs } from 'assets/data/data.source.contacto';
import { scrollToTop } from 'components/util';
import React, { Component } from 'react';



class SocialButton extends Component {

    state = {
        is_visible: false
    };

    componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", function (e) {
            scrollComponent.toggleVisibility();
        });
    }

    toggleVisibility() {
        if (window.pageYOffset > 300) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }

    render() {
        const { is_visible } = this.state;
        return (
            <>
                <div className="float-social-button">
                    <div onClick={() => scrollToTop()}>
                        <div className="icon">
                            {ContactoDs.whatsapp.linkIcon}
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default SocialButton;