import Footer from 'layout/footer';
import Header from 'layout/header';
import Header1 from 'layout/header1';
import React, { Component } from 'react';
import HeaderPage from './HeaderPage';

class CustomPage extends Component {
    render() {
        const { ...props } = this.props;
        const { children, header, isHome, image, title, subtitle } = props;
        return (
            <>
                {isHome ? (<Header />) : (<Header1 />)}
                {header && (
                    < HeaderPage
                        image={image}
                        title={title}
                        subtitle={subtitle} />
                )}
                {children}
                <Footer />
            </>
        )
    }
}
export default CustomPage;