import { Spin } from 'antd';
import React, { Suspense } from 'react';
import './background.scss';

const Background = ({ src, alt = 'background', className = 'background1' }) => {
  return (
    <div className={className} />);
};
const Background2 = ({ src, alt = 'background', className = 'background' }) => {
  return (
    <Suspense fallback={<Spin />}>
      <img loading='lazy' alt={alt} src={src} className="background" />
    </Suspense>);
};
export default Background;
