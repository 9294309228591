import ListItem from 'components/observe/ListItem';
import lazyLoadImage, { config } from "config/lazyLoadImage";
import React, { useState } from "react";


const ServiceFilter1 = props => {
    const { headerImage, images, titulo, subtitulo, filterData, FILTER_DEFS } = props;

    const [state, setState] = useState({
        list: images,
        filterKey: "NONE"
    });

    const { list, filterKey } = state;
    const filteredList = list.filter(FILTER_DEFS[filterKey]);
    const observer = new window.IntersectionObserver(lazyLoadImage, config);

    return (
        <>
            <section class="gallery-section">
                <div class="sec-bg" style={{ backgroundImage: "url(" + headerImage + ")" }}></div>
                <div class="sortable-masonry">

                    <div class="auto-container">
                        <div class="sec-title light text-center">
                            <div class="sub-title">{subtitulo}</div>
                            <h2>{titulo}</h2>
                        </div>
                        {/* <!--Filter--> */}
                        <div class="filters text-center light">
                            <ul class="filter-tabs filter-btns">
                                {filterData.map((item, index) =>
                                    <li key={'im-index' + index} class={state.filterKey === item.id ? "active filter" : "filter"} onClick={() => setState({ ...state, filterKey: item.id })}><span>{item.label}</span></li>

                                )}
                            </ul>
                        </div>
                    </div>
                    <div class="auto-container">
                        <div class="items-container row"> 
                            {/* <!-- Gallery Block One --> */}
                            {filteredList.map(image => (
                                <ListItem key={image.id}
                                    image={image}
                                    observer={observer} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceFilter1;
