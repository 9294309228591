import LinkComponent from 'components/link';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HeaderPage extends Component {
    render() {
        const { ...props } = this.props;
        const { image, title, subtitle } = props;
        return (
            <>
                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg"
                        style={{ backgroundImage: "url(" + image + ")" }}>
                    </div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <div class="sec-title light text-center">
                            <div class="sub-title">{"Inti Kamari"}</div>

                            <h1>{title}</h1>
                            <ul class="bread-crumb clearfix">
                                <li><LinkComponent to={'/'}>Inicio</LinkComponent></li>
                                <li class="active">{subtitle}</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
export default HeaderPage;