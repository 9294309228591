import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_ES } from './es/translations';
import { TRANSLATIONS_EN } from './en/translations';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    resources: {
      es: {
        translation: TRANSLATIONS_ES,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
    },
  });

const changeLanguage = lng => {
  i18n.changeLanguage(lng);
};

const handleChangeLng = flag => { 
  switch (flag) {
    case 'english':
      i18n.changeLanguage('en');
      break;
    case 'spain':
      i18n.changeLanguage('es');
      break;
  }
};

export { i18n, handleChangeLng };
